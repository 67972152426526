import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { TOKEN_KEY } from "@hooks/useJwt.hook";

export default function Home() {
    const router = useRouter();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        handleRedirect();

    }, []);

    const handleRedirect = async () => {
        const token = localStorage.getItem(TOKEN_KEY);

        /**
         * If there is a token in localStorage assume it's valid and redirect the user. If the token
         * turns out to be invalid or expired then the server will respond with a 401. This 401 will
         * force the user back to this screen and clear the token so it is null.
         */
        if (token !== null) {
            await router.push('/admin/locations');
            return;
        }

        setIsLoading(false);

    }

    return (
        <>
            {!isLoading && <div className="h-full w-full flex items-center justify-center">
                <a className="px-8 py-4 font-medium rounded-md shadow-md bg-blue-500 hover:bg-blue-600 transition text-white" href={`${process.env.API_URL}/auth/google`}>Log in with Google</a>
            </div>}
            {isLoading && <div className="flex w-full h-full items-center justify-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"/>
                    <path className="opacity-75" fill="currentColor"
    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                </svg>
            </div>}
        </>
    );
}
